<template>
    <advances-module-component/>
</template>

<script>
    import AdvancesModuleComponent from "@/components/admin/AdvancesModuleComponent";

    export default {
        name: "AdvancesModule",
        title: "Avances de Proyectos | Equity Baja",
        components: {AdvancesModuleComponent}
    }
</script>

<style scoped>

</style>